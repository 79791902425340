.form {
	display: block;
	margin: 0;
	padding: 0;
}

.form .titleForm {
	overflow: hidden;
	display: block;
	margin: 0 0 25px 0;
	padding: 0;
	font-size: 11pt;
	line-height: 24px;
	font-weight: bold;
	text-transform: uppercase;
	color: var(--white);
}
.form .titleForm.center {
	text-align: center;
}

.form .errorForm {
	margin: 15px 0;
	padding: 10px 25px;
	font-size: 9pt;
	line-height: 20px;
	text-align: left;
	color: var(--white);
	background-color: var(--red);
	border-radius: 10px;
}
.form .valid {
	margin: 15px 0;
	padding: 10px 25px;
	font-size: 9pt;
	line-height: 20px;
	text-align: left;
	color: var(--white);
	background-color: var(--green);
	border-radius: 20px;
}

.form .wrapper {
	margin: 0;
	padding: 0;
	overflow: hidden;
	border-radius: 10px;
	background-color: var(--backgroundColor);
}

.form .containerInput {
	display: block;
	margin: 0;
	padding: 0;
	transition: all .2s;
}
.form .containerInput:hover {
	background-color: rgba(255, 255, 255, 0.03);
}
.form .containerInput.flex {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}
.form .containerInput.paddingBottom {
	padding: 0 0 8px 0;
}

.form .containerInput:focus-within {
	background-color: rgba(255, 255, 255, 0.05);
}

.form .containerInput > .icon {
	display: block;
	width: 24px;
	height: 24px;
	margin: 18px 25px;
	padding: 0;
}
.form .containerInput > .icon > svg {
	fill: var(--whity);
	transition: all .2s;
}
.form .containerInput:focus-within > .icon > svg {
	fill: var(--white);
	transition: all .2s;
}

.form .containerInput > .input {
	width: 100%;
	display: block;
	margin: 0;
	padding: 0;
}
.form .containerInput > .input.withoutIcon {
	margin: 0 25px;
}

.form .containerInput .label {
	display: block;
	height: 15px;
	margin: 0;
	padding: 0;
	font-size: 8pt;
	line-height: 15px;
	text-align: left;
	font-family: "NotoSansJP-Regular", "Trebuchet MS", Arial, "Times New Roman", sans-serif;
	color: var(--whity);
	transition: all .2s;
}
.form .containerInput .label.marginBottom {
	margin-bottom: 10px;
}
.form .containerInput.flex .label {
	margin-top: 10px;
}
.form .containerInput:focus-within .label {
	color: var(--white)
}

.form .containerInput .inputText {
	display: block;
	width: 100%;
	margin: 0;
	padding: 0;
}
.form .containerInput .inputText > input {
	width: 100%;
	margin: 0;
	padding: 0;
	font-size: 10pt;
	color: var(--white);
	border: none;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	background-color: transparent;
	outline: none;
	transition: all .25s;
}
.form .containerInput .inputText > select {
	display: block;
	width: calc(100% - 2px);
	min-height: 37px;
	margin: 0;
	padding: 0 50px 0 15px;
	font-size: 10pt;
	font-weight: bold;
	border-radius: 20px;
	border: 1px solid var(--blacky);
	background-color: var(--white);
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	outline: none;
	background-image: url("../../image/resource/down-arrow-blue.svg");
	background-size: 14px 14px;
	background-position: center right 15px;
	background-repeat: no-repeat;
	transition: all .25s;
}

.form .containerInput .inputRichText {
	display: block;
	width: 100%;
	margin: 0;
	padding: 0;
}
.form .containerInput .inputRichText > .input {
	width: 100%;
	margin: 0;
	padding: 0;
}
.form .containerInput .inputRichText > .input > .ql-container > .ql-editor {
	margin: 0;
	padding: 7px 0 0 0;
}
.form .containerInput .inputRichText > .input > .ql-container > .ql-editor.ql-blank::before {
	left: 0;
	right: 0;
	color: var(--gray);
	font-style: normal;
}

.inputSwitch {
	height: 40px;
	margin: 0;
	border-radius: 20px;
	transition: all .15s;
}
.inputSwitch.marginLR {
	margin-left: 10px;
	margin-right: 10px;
}
.inputSwitch.marginTop {
	margin-top: 30px;
}
.inputSwitch.marginBottom {
	margin-bottom: 30px;
}
.inputSwitch.marginTB {
	margin-top: 0;
	margin-bottom: 20px;
}
.inputSwitch.checked {
	background-color: var(--blacky);
}
.inputSwitch > label {
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 40px;
	margin: 10px 0 0 0;
	padding: 0;
	cursor: pointer;
}
.inputSwitch.inline > label {
	margin: 0 auto;
}
.inputSwitch > label > span {
	display: block;
	width: calc(75% - 10px);
	margin: 0;
	padding: 0 0 0 15px;
	font-size: 9pt;
	text-align: left;
	line-height: 20px;
	color: var(--white);
}
.inputSwitch.inline > label > span {
	display: none;
}
.inputSwitch > label > .react-switch {
	margin: 0 15px 0 0;
	padding: 0;
}
.inputSwitch.inline > label > .react-switch {
	margin: 4px 30px;
	padding: 0;
}

.form .belowLogin {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	margin: 20px 0 0 0;
}

.form .labelCheckbox {
	float: left;
	display: flex;
	margin: 0;
	padding: 0;
}
.form .labelCheckbox > #rememberMe {
	display: block;
	margin: 0 10px 0 0;
}
.form .labelCheckbox > p {
	margin: 0;
	padding: 0;
	font-size: 8pt;
	line-height: 20px;
	text-align: left;
	color: var(--white);
	cursor: pointer;
	transition: all .2s;
}
.form .labelCheckbox:hover > p {
	color: var(--white);
}
.form .labelCheckbox > input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}
.form .labelCheckbox > .checkmark {
	height: 22px;
	width: 22px;
	margin: 0 10px 0 0;
	background-color: var(--white);
	border-radius: 5px;
	transition: all .15s;
}
.form .labelCheckbox:hover > input ~ .checkmark {
	background-color: var(--white);
}
.form .labelCheckbox > input:checked ~ .checkmark {
	background-color: var(--blue)
}
.form .labelCheckbox > .checkmark:after {
	content: "";
	display: none;
}
.form .labelCheckbox > input:checked ~ .checkmark:after {
	display: block;
}
.form .labelCheckbox .checkmark:after {
	margin: 4.5px 0 0 8.5px;
	width: 4px;
	height: 8px;
	border: solid white;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.form .checkPwd {
	display: block;
	margin: 20px 0 0 10px;
	padding: 0;
	list-style-type: none;
}
.form .checkPwd > .item {
	padding: 0 0 0 25px;
	font-size: 8pt;
	line-height: 20px;
	color: var(--whity);
	background-repeat: no-repeat;
	background-position: left center;
	background-size: 14px 14px;
	background-image: url("../../image/resource/list-none.svg");
	transition: all .15s;
}
.form .checkPwd > .item.nok {
	color: var(--red);
	background-image: url("../../image/resource/list-error.svg");
}
.form .checkPwd > .item.ok {
	color: var(--green);
	background-image: url("../../image/resource/list-checked.svg");
}

.form .forgot {
	display: block;
	margin: 0;
	padding: 0;
	font-size: 8pt;
	line-height: 20px;
	text-align: right;
	color: var(--white);
	text-decoration: none;
	transition: all 0.15s;
}
.form .forgot:hover {
	color: var(--white);
	text-decoration: underline;
}

.form .submit {
	display: block;
	margin: 20px 0 0 0;
	padding: 10px 25px;
	font-size: 9pt;
	line-height: 20px;
	font-family: "NotoSansJP-SemiBold", "Trebuchet MS", Arial, "Times New Roman", sans-serif;
	text-align: left;
	color: var(--white);
	background-color: var(--blue);
	border-radius: 20px;
	border: none;
	cursor: pointer;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	transition: all .15s;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.form .submit.disable {
	cursor: not-allowed;
	opacity: 0.5;
	filter: alpha(opacity=50);
}
.form .submit.right {
	float: right;
}
.form .submit.disable {

}
.form .submit:not(.disable):hover {
	background-color: var(--lightBlue);
}
.form .submit:not(.disable):active {
	background-color: var(--blue);
}

.form > .containerButtons {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}
.form > .containerButtons.left {
	align-items: flex-start;
	justify-content: flex-start;
}
.form > .containerButtons.right {
	align-items: flex-end;
	justify-content: flex-end;
}

.form .inputTag {
	display: flex;
	width: calc(100% - 2px);
	min-height: 37px;
	margin: 0;
	padding: 0;
	border-radius: 20px;
	border: 1px solid var(--blacky);
	background-color: var(--white);
}
.form .inputTag > .containerTag {
	display: block;
	height: 100%;
	margin: 0;
	padding: 0 0 5px 5px;
}
.form .inputTag > .containerTag.buttonWithTitle {
	width: calc(100% - 75px);
}
.form .inputTag > .containerTag.buttonSvg {
	width: calc(100% - 25px);
}
.form .inputTag > .containerTag > .tag {
	display: block;
	float: left;
	height: 28px;
	margin: 5px 5px 0 0;
	padding: 0 10px;
	border-radius: 14px;
	background-color: var(--blue);
}
.form .inputTag > .containerTag > .tag > p {
	display: block;
	float: left;
	margin: 0;
	padding: 0;
	font-size: 9pt;
	line-height: 26px;
	color: var(--white);
}
.form .inputTag > .containerTag > .tag > svg {
	float: left;
	width: 10px;
	height: 10px;
	margin: 9px 0 0 15px;
	padding: 0;
	fill: var(--white);
	cursor: pointer;
	transition: all .15s;
}
.form .inputTag > .containerTag > .tag > svg:hover {
	transform: rotate(90deg);
}
.form .inputTag > .containerTag input[type="text"] {
	display: block;
	float: left;
	width: 100px;
	height: 27px;
	margin: 0;
	background-color: transparent;
	border-radius: 0;
	border: none;
	font-size: 9pt;
	text-align: left;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
}
.form .inputTag > .button {
	display: block;
	width: 70px;
	height: 28px;
	margin: 5px 5px 0 0;
	padding: 0;
	font-size: 9pt;
	line-height: 26px;
	text-align: center;
	background-color: var(--blue);
	color: var(--white);
	border-radius: 14px;
	transition: all .15s;
	cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.form .inputTag > svg {
	display: block;
	width: 20px;
	height: 20px;
	margin: 7px 5px 0 0;
	padding: 0;
	fill: var(--blue);
	transition: all .15s;
	cursor: pointer;
}
.form .inputTag > .button:hover {
	background-color: var(--lightBlue);
}
.form .inputTag > svg:hover {
	fill: var(--lightBlue);
}
.form .listTag {
	margin: 0;
	padding: 0;
	list-style-type: none;
}
.form .listTag > li.empty {
	padding-top: 15px;
	font-size: 10pt;
	color: var(--white);
}
.form .listTag > li.choice {
	font-size: 10pt;
	color: var(--white);
}
