@font-face {
	font-family: "NotoSansJP-Black";
	src: url("font/NotoSansJP/NotoSansJP-Black.ttf") format("truetype");
}
@font-face {
	font-family: "NotoSansJP-Bold";
	src: url("font/NotoSansJP/NotoSansJP-Bold.ttf") format("truetype");
}
@font-face {
	font-family: "NotoSansJP-ExtraBold";
	src: url("font/NotoSansJP/NotoSansJP-ExtraBold.ttf") format("truetype");
}
@font-face {
	font-family: "NotoSansJP-ExtraLight";
	src: url("font/NotoSansJP/NotoSansJP-ExtraLight.ttf") format("truetype");
}
@font-face {
	font-family: "NotoSansJP-Light";
	src: url("font/NotoSansJP/NotoSansJP-Light.ttf") format("truetype");
}
@font-face {
	font-family: "NotoSansJP-Medium";
	src: url("font/NotoSansJP/NotoSansJP-Medium.ttf") format("truetype");
}
@font-face {
	font-family: "NotoSansJP-Regular";
	src: url("font/NotoSansJP/NotoSansJP-Regular.ttf") format("truetype");
}
@font-face {
	font-family: "NotoSansJP-SemiBold";
	src: url("font/NotoSansJP/NotoSansJP-SemiBold.ttf") format("truetype");
}
@font-face {
	font-family: "NotoSansJP-Thin";
	src: url("font/NotoSansJP/NotoSansJP-Thin.ttf") format("truetype");
}

:root {
	--backgroundColor: #313338;
	--backgroundBoxColor: #2b2d31;
	--backgroundBoxColor2: #1e1f22;
	--shadowBoxColor: #1a1a1a;
	--black: #000000;
	--blacky: #333333;
	--white: #ffffff;
	--whityy: #eeeeee;
	--whity: #cccccc;
	--lightGray: #8e8e8e;
	--gray: #676767;
	--lightBlue: #365e7d;
	--blue: #2b4d66;
	--lightRed: #bf3c3c;
	--red: #982f2f;
	--green: #3D8366;
}
