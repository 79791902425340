.site > .table {
    width: calc(100vw - 30px);
    height: calc(100vh - 85px);
    max-height: calc(100vh - 85px);
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0 auto;
    padding: 10px 0 15px 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.site > .table::-webkit-scrollbar {
    display: none;
}

.site > .table > .containerActions {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 15px 0;
    padding: 0;
}
.site > .table > .containerActions > .pagination {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    margin: 0;
    padding: 0;
}
.site > .table > .containerActions > .pagination > .title {
    margin: 0;
    padding: 0 0 0 25px;
    font-size: 14pt;
    line-height: 40px;
}
.site > .table > .containerActions > .actions {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.site > .table > .containerActions > .actions > .button {
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    height: 40px;
    margin: 0 0 0 15px;
    padding: 0 25px;
    background-color: var(--backgroundBoxColor2);
    border-radius: 20px;
    cursor: pointer;
    transition: all .2s;
}
.site > .table > .containerActions > .actions > .button:hover {
    background-color: var(--backgroundBoxColor);
}
.site > .table > .containerActions > .actions > .button > .icon {
    width: 16px;
    height: 16px;
    margin: 12px 15px 0 0;
    fill: var(--white);
}
.site > .table > .containerActions > .actions > .button > .text {
    margin: 0;
    padding: 0;
    text-align: left;
    font-size: 9pt;
    line-height: 40px;
    color: var(--white);
}

.site > .table > .containerFilters {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0 0 15px 0;
    padding: 0;
}
.site > .table > .containerFilters > .searchBar {
    overflow: hidden;
    display: block;
    height: 20px;
    margin: 0;
    padding: 10px 25px;
    font-size: 11pt;
    line-height: 20px;
    background-color: var(--whity);
    border-radius: 20px;
    cursor: pointer;
    transition: all .2s;
    outline: none;
    border: none;
}
.site > .table > .containerFilters > .searchBar.extend {
    width: calc(100% - 50px);
}
.site > .table > .containerFilters > .searchBar:hover,
.site > .table > .containerFilters > .searchBar:has(input:focus),
.site > .table > .containerFilters > .searchBar > input:focus {
    background-color: var(--white);
}
.site > .table > .containerFilters > .searchBar > input {
    display: block;
    width: 100%;
    margin: 0;
    padding: 2px 0 0 0;
    background-color: transparent;
    appearance: none;
    border: none;
    outline: none;
}

.site > .table > .containerTable {
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    margin: 0;
    padding: 0;
    border-radius: 20px;
}
.site > .table > .containerTable > table {
    display: table;
    width: 100%;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    table-layout: fixed;
}
.site > .table > .containerTable > table > tbody {
    background-color: var(--white);
    color: var(--black);
}
.site > .table > .containerTable > table > thead > tr {
    height: 40px;
}
.site > .table > .containerTable > table > tbody > tr {
    height: 60px;
    transition: all .2s;
}
.site > .table > .containerTable > table > tbody > tr:hover {
    background-color: var(--whityy);
}
.site > .table > .containerTable > table > thead > tr > th,
.site > .table > .containerTable > table > tbody > tr > td {
    padding: 0 25px;
    text-align: left;
    font-family: "NotoSansJP-SemiBold", "Trebuchet MS", Arial, "Times New Roman", sans-serif;
}
.site > .table > .containerTable > table > thead > tr > th > .header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}
.site > .table > .containerTable > table > thead > tr > th > .header.sorting {
    cursor: pointer;
    transition: all .2s;
}
.site > .table > .containerTable > table > thead > tr > th > .header.sorting:hover {
    filter: brightness(80%);
}
.site > .table > .containerTable > table > thead > tr > th > .header > .text {
    margin: 0;
    padding: 0;
    font-size: 10pt;
    font-weight: normal;
}
.site > .table > .containerTable > table > thead > tr > th > .header > .sortIcon {
    width: 14px;
    height: 14px;
    margin: 3px 0 0 5px;
    fill: var(--white);
}
.site > .table > .containerTable > table > thead > tr > th > .header > .sortIcon.asc {
    width: 10px;
    height: 10px;
    margin: 5px 0 0 7px;
}
.site > .table > .containerTable > table > tbody > tr > td {
    font-size: 10pt;
}
.site > .table > .containerTable > table > thead > tr > th.openable,
.site > .table > .containerTable > table > tbody > tr > td.openable {
    width: 100px;
    max-width: 100px;
}
.site > .table > .containerTable > table > tbody > tr > td.openable > .openable {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    height: 40px;
    border-radius: 20px;
    margin: 0;
    padding: 0;
    background-color: var(--whity);
    cursor: pointer;
    transition: all .15s;
}
.site > .table > .containerTable > table > tbody > tr > td.openable > .openable > svg {
    width: 16px;
    height: 16px;
    margin: 12px 0 0 15px;
    fill: var(--blacky);
    transition: all .15s;
}
.site > .table > .containerTable > table > tbody > tr > td.openable > .openable > p {
    height: 40px;
    margin: 0 15px 0 0;
    padding: 0;
    line-height: 40px;
    color: var(--blacky);
    transition: all .15s;
}
.site > .table > .containerTable > table > tbody > tr > td.openable > .openable:hover {
    background-color: var(--lightBlue);
}
.site > .table > .containerTable > table > tbody > tr > td.openable > .openable:hover > svg {
    fill: var(--white);
}
.site > .table > .containerTable > table > tbody > tr > td.openable > .openable:hover > p {
    color: var(--white);
}
.site > .table > .containerTable > table > tbody > tr > td.empty {
    text-align: center;
    color: var(--lightGray);
}