.site > header {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    margin: 0;
    padding: 0;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.site > header > .goBack {
    width: 20px;
    height: 20px;
    margin: 15px 5px 15px 15px;
    padding: 0;
    fill: var(--white);
    cursor: pointer;
    transition: all .2s;
}
.site > header > .goBack:hover {
    fill: var(--whity);
}

.site > header > .containerTitle {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    flex-grow: 1;
    margin: 10px 0 10px 0;
    padding: 0;
}
.site > header > .containerTitle > .logo {
    width: 40px;
    height: 40px;
    margin: 0 20px 0 15px;
    fill: var(--whity);
    cursor: pointer;
    transition: all .2s;
}
.site > header > .containerTitle > .logo:hover {
    filter: brightness(80%);
}
.site > header > .containerTitle > h1 {
    margin: 0;
    padding: 0;
    font-size: 14pt;
    font-weight: bold;
    line-height: 40px;
    text-transform: capitalize;
}

.site > header > .user {
    margin: 10px 15px 0 0;
    padding: 0 25px;
    background-color: var(--backgroundBoxColor2);
    border-radius: 20px;
    cursor: pointer;
    transition: all .2s;
}
.site > header > .user:hover {
    background-color: var(--backgroundBoxColor);
}
.site > header > .user > .hello {
    margin: 0;
    padding: 0 7px 0 0;
    font-size: 9pt;
    line-height: 40px;
    color: var(--whity);
}
.site > header > .user > .name {
    margin: 0;
    padding: 0;
    font-size: 9pt;
    line-height: 40px;
}

.site > header > .userOptions {
    position: fixed;
    visibility: hidden;
    overflow-x: hidden;
    overflow-y: auto;
    top: 50px;
    right: 15px;
    opacity: 0;
    filter: alpha(opacity=0);
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: var(--backgroundBoxColor2);
    border-radius: 20px;
    animation-duration: .2s;
    animation-name: disappearUserOptions;
}
.site > header > .userOptions.appear {
    visibility: visible;
    top: 70px;
    opacity: 1;
    filter: alpha(opacity=100);
    animation-name: appearUserOptions;
}
.site > header > .userOptions > .item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0;
    padding: 0 25px;
    cursor: pointer;
    transition: all .2s;
}
.site > header > .userOptions > .item.red {
    background-color: var(--red);
}
.site > header > .userOptions > .item:hover {
    background-color: var(--backgroundBoxColor);
}
.site > header > .userOptions > .item.red:hover {
    background-color: var(--lightRed);
}
.site > header > .userOptions > .item > .icon {
    width: 20px;
    height: 20px;
    margin: 10px 15px 0 0;
    fill: var(--white);
}
.site > header > .userOptions > .item > .text {
    margin: 0;
    padding: 0;
    text-align: left;
    font-size: 9pt;
    line-height: 40px;
    color: var(--white);
}

@keyframes appearUserOptions {
    0% {
        top: 50px;
        opacity: 0;
        filter: alpha(opacity=0);
    }
    100% {
        top: 70px;
        opacity: 1;
        filter: alpha(opacity=100);
    }
}
@keyframes disappearUserOptions {
    0% {
        top: 70px;
        opacity: 1;
        filter: alpha(opacity=100);
    }
    100% {
        top: 50px;
        opacity: 0;
        filter: alpha(opacity=0);
    }
}