.error {
    position: fixed;
    z-index: 999;
    bottom: 25px;
    left: 50%;
    height: 50px;
    margin: 0;
    padding: 0 25px;
    transform: translate(-50%, 0%);
    background-color: var(--red);
    color: white;
    font-size: 11pt;
    line-height: 50px;
    border-radius: 25px;
    animation-duration: .5s;
    animation-name: appearError;
    box-shadow: 0 0 6px var(--backgroundBoxColor2);
}
.error.disappear {
    bottom: -50px;
    animation-duration: .5s;
    animation-name: disappearError;
}

@keyframes appearError {
    0% {
        bottom: -50px;
    }
    50%{
        bottom: 40px;
    }
    100%{
        bottom: 25px;
    }
}
@keyframes disappearError {
    0% {
        bottom: 25px;
    }
    50%{
        bottom: 40px;
    }
    100%{
        bottom: -50px;
    }
}