/* For Mobile */
@media screen and (max-width: 767px) {
	.box {
		top: 0;
		left: 0;
		width: calc(100vw - 50px);
		height: calc(100vh - 50px);
	}
	.box > .logo {
		display: block;
		width: 70px;
		height: 70px;
		margin: 0 auto 25px auto;
	}
	.box > h1 {
		font-size: 14pt;
		margin: 10px 0 10px 0;
	}
	.box > p {
		font-size: 9pt;
		margin: 0 0 25px 0;
	}
}

/* For Tablet */
@media screen and (min-width: 768px) {
	.box {
		top: 50%;
		left: 50%;
		width: 350px;
		height: auto;
		transform: translate(-50%, -50%);
		border-radius: 12px;
		box-shadow: 0 0 5px var(--shadowBoxColor);
		transition: all .25s;
	}
	.box > .logo {
		display: block;
		width: 70px;
		height: 70px;
		margin: 0 auto 25px auto;
	}
	.box > h1 {
		font-size: 14pt;
		margin: 10px 0 10px 0;
	}
	.box > p {
		font-size: 9pt;
		margin: 0 0 25px 0;
	}
}

/* For Desktop */
@media screen and (min-width: 992px) {

}