.Overlay {
    visibility: hidden;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, .2);
    animation-duration: .3s;
    animation-name: disappearOverlay;
}
.Overlay.appear {
    visibility: visible;
    opacity: 1;
    filter: alpha(opacity=100);
    animation-name: appearOverlay;
}
.OverBox {
    visibility: hidden;
    position: fixed;
    z-index: 101;
    width: 500px;
    top: 50%;
    left: 50%;
    margin: 0;
    padding: 25px;
    transform: translate(-50%, -50%);
    background-color: var(--backgroundBoxColor);
    box-shadow: 0 0 3px var(--black);
    animation-duration: .2s;
    animation-timing-function: ease-out;
    animation-name: disappearOverBox;
    border-radius: 20px;
}
.OverBox.confirm {
    width: 400px;
}
.OverBox.overList {
    width: 400px;
    background-color: var(--backgroundBoxColor2);
}
.OverBox.appear {
    visibility: visible;
    opacity: 1;
    filter: alpha(opacity=100);
    animation-name: appearOverBox;
}

.OverBox > .loader {
    display: none;
    width: 30px;
    height: 30px;
    margin: 40px auto;
}
.OverBox > .loader.visible {
    display: block;
}

.OverBox > .title {
    margin: 0 0 25px 0;
    padding: 0;
    font-size: 11pt;
    text-align: center;
}
.OverBox > .question {
    margin: 0 0 25px 0;
    padding: 0;
    font-size: 9pt;
    line-height: 20px;
    text-align: left;
}

.OverBox > .form {
    margin: 0;
    padding: 0;
}

.OverBox > .removeLink {
    height: 40px;
    margin: 25px 0 0 0;
    padding: 0;
    font-size: 10pt;
    line-height: 40px;
    font-family: "NotoSansJP-Bold", "Trebuchet MS", Arial, "Times New Roman", sans-serif;
    color: var(--red);
    text-align: center;
    cursor: pointer;
    border-radius: 20px;
    transition: all .15s;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.OverBox > .removeLink:hover {
    background-color: var(--red);
    color: var(--white);
}

.OverBox > .containerChoices {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 30px 0 0 0;
    padding: 0;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.OverBox > .containerChoices > .choice {
    width: calc((100% / 2) - 62px);
    margin: 0;
    padding: 25px;
    cursor: pointer;
    transition: all .2s;
    border-radius: 20px;
    background-color: var(--backgroundBoxColor2);
}
.OverBox > .containerChoices > .choice:hover {
    filter: brightness(125%);
}
.OverBox > .containerChoices > .choice > .icon {
    display: block;
    width: 30px;
    height: 30px;
    margin: 0 auto;
    padding: 0 0 25px 0;
    fill: var(--white);
}
.OverBox > .containerChoices > .choice > .text {
    margin: 0;
    padding: 0;
    font-size: 10pt;
    line-height: 22px;
    text-align: center;
    color: var(--white);
}

.OverBox > .containerButtons {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    margin: 25px 0 0 0;
    padding: 0;
}
.OverBox > .containerButtons > .cancel {
    display: block;
    margin: 0;
    padding: 0 25px;
    font-size: 9pt;
    line-height: 20px;
    font-family: "NotoSansJP-SemiBold", "Trebuchet MS", Arial, "Times New Roman", sans-serif;
    text-align: left;
    color: var(--blacky);
    background-color: var(--whity);
    border-radius: 20px;
    border: none;
    cursor: pointer;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    transition: all .15s;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.OverBox > .containerButtons > .cancel:hover {
    background-color: var(--lightGray);
}
.OverBox > .containerButtons > .cancel:active {
    background-color: var(--gray);
}
.OverBox > .containerButtons > .submit {
    display: block;
    margin: 0;
    padding: 0 25px;
    font-size: 9pt;
    line-height: 20px;
    font-family: "NotoSansJP-SemiBold", "Trebuchet MS", Arial, "Times New Roman", sans-serif;
    text-align: left;
    color: var(--white);
    background-color: var(--blue);
    border-radius: 20px;
    border: none;
    cursor: pointer;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    transition: all .15s;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.OverBox > .containerButtons > .submit.remove {
    background-color: var(--red);
}
.OverBox > .containerButtons > .submit.disable {
    cursor: not-allowed;
    opacity: 0.5;
    filter: alpha(opacity=50);
}
.OverBox > .containerButtons > .submit:not(.disable):hover {
    background-color: var(--lightBlue);
}
.OverBox > .containerButtons > .submit:not(.disable):active {
    background-color: var(--blue);
}
.OverBox > .containerButtons > .submit.remove:not(.disable):hover {
    background-color: var(--lightRed);
}
.OverBox > .containerButtons > .submit.remove:not(.disable):active {
    background-color: var(--red);
}
.OverBox > .containerButtons > .cancel > p,
.OverBox > .containerButtons > .submit > p {
    margin: 0;
    padding: 0;
    line-height: 40px;
    font-size: 10pt;
}

@keyframes appearOverlay {
    0% {
        opacity: 0;
        filter: alpha(opacity=0);
    }
    100% {
        opacity: 1;
        filter: alpha(opacity=100);
    }
}
@keyframes disappearOverlay {
    0% {
        opacity: 1;
        filter: alpha(opacity=100);
    }
    100% {
        opacity: 0;
        filter: alpha(opacity=0);
    }
}
@keyframes appearOverBox {
    0% {
        top: 53%;
        opacity: 0;
        filter: alpha(opacity=0);
    }
    100% {
        opacity: 1;
        filter: alpha(opacity=100);
    }
}
@keyframes disappearOverBox {
    0% {
        opacity: 1;
        filter: alpha(opacity=100);
    }
    100% {
        top: 53%;
        opacity: 0;
        filter: alpha(opacity=0);
    }
}

@media only screen and (max-width: 1199px) {

}
@media only screen and (max-width: 640px) {
    .OverBox {
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        transform: translate(0, 0);
    }
}