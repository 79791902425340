.site > .menu {
    max-width: 1200px;
    height: calc(100vh - 130px);
    max-height: calc(100vh - 130px);
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    margin: 0 auto;
    padding: 20px 0 50px 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.site > .menu::-webkit-scrollbar {
    display: none;
}
.site > .menu > .loader {
    position: fixed;
    left: 50%;
    top: 50%;
    width: 50px;
    height: 50px;
    opacity: 0;
    filter: alpha(opacity=0);
    transition: all .25s;
    transform: translate(-50%, -50%);
}
.site > .menu > .loader.visible {
    opacity: 1;
    filter: alpha(opacity=100);
}
.site > .menu > .item {
    width: 200px;
    margin: 40px 20px 0 20px;
    padding: 25px;
    border-radius: 20px;
    background-color: var(--backgroundBoxColor2);
    transform: scale(95%);
    opacity: 0;
    filter: alpha(opacity=0);
    cursor: pointer;
    transition: all .2s;
}
.site > .menu > .item.hidden {
    display: none;
}
.site > .menu > .item:not(.edit):hover {
    filter: brightness(135%);
}
.site > .menu > .item.noVisible {
    visibility: collapse;
}
.site > .menu > .item.appear {
    transform: scale(100%);
    opacity: 1;
    filter: alpha(opacity=100);
    animation-name: appear;
    animation-duration: 1s;
}
.site > .menu > .item > svg {
    width: 40px;
    height: 40px;
    display: block;
    margin: 5px auto 30px auto;
}
.site > .menu > .item > .containerEdit {
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 10px;
    border-radius: 5px 20px 5px 20px;
    fill: var(--white);
    opacity: 0;
    filter: alpha(opacity=0);
    transition: all .15s;
    cursor: pointer;
}
.site > .menu > .item > .containerEdit.appear {
    opacity: 1;
    filter: alpha(opacity=100);
}
.site > .menu > .item > .containerEdit > svg {
    width: 16px;
    height: 16px;
    padding: 2px;
    transition: all .15s;
}
.site > .menu > .item > .iconLetters {
    display: block;
    width: 50px;
    height: 50px;
    margin: 0 auto 25px auto;
    padding: 0;
    text-align: center;
    line-height: 50px;
    border-radius: 25px;
    color: var(--white);
}
.site > .menu > .item > .title {
    margin: 0;
    padding: 0;
    text-align: center;
    text-transform: capitalize;
}

@keyframes appear {
    0% {
        transform: scale(95%);
        opacity: 0;
        filter: alpha(opacity=0);
    }
    100%{
        transform: scale(100%);
        opacity: 1;
        filter: alpha(opacity=100);
    }
}