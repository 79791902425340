html, body {
	overflow: hidden;
	width: 100vw;
	height: 100vh;
	margin: 0;
	padding: 0;
	font-family: "NotoSansJP-Regular", "Trebuchet MS", Arial, "Times New Roman", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--backgroundColor);
	color: var(--white);
}

#root {
	overflow: hidden;
	margin: 0;
	padding: 0;
}

.loader {
	display: block;
}
.loader.submitFormLogin {
	width: 20px;
	height: 20px;
	margin: 0 15px;
	padding: 0;
}
.loader.submitForm {
	width: 20px;
	height: 20px;
	margin: 10px 15px 0 15px;
	padding: 0;
}
.loader.table {
	width: 28px;
	height: 28px;
	margin: 0 auto;
	padding: 0;
}

.box {
	position: fixed;
	margin: 0;
	padding: 25px;
	background-color: var(--backgroundBoxColor);
}

.overlayer {
	position: fixed;
	z-index: 100;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background-color: rgba(255, 255, 255, 0.1);
	transition: all 0.15s;
	animation: opacityUp 0.3s ease;
}
.overlayer.hover {
	z-index: 102;
}

.wrapOverbox {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 100%;
	height: 100%;
	min-height: 100vh;
	max-height: 100%;
	margin: 0;
	padding: 0;
	transition: all 0.15s;
}

.overbox {
	z-index: 101;
	position: relative;
	overflow-x: hidden;
	overflow-y: auto;
	max-height: calc(100vh - 100px);
	margin: 0;
	padding: 0;
	background-color: var(--backgroundBoxColor);
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
	transition: all 0.15s;
	animation: boxUp 0.3s ease;
}
.overbox.smallWidthAutoHeight {
	overflow-x: hidden;
	overflow-y: auto;
	width: 450px;
	height: auto;
	margin: 0 auto;
	padding: 0;
}
.overbox.paddingBox {
	padding: 25px;
}
.overbox.smallWidthAutoHeight.paddingBox {
	width: 400px;
}

.containerEditable {
	position: fixed;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	height: 50px;
	bottom: 15px;
	right: 15px;
	margin: 0;
	padding: 0 25px;
	background-color: var(--backgroundBoxColor2);
	border-radius: 20px;
	cursor: pointer;
	transition: all .2s;
	animation-duration: .5s;
	animation-name: appearError;
}
.containerEditable:hover {
	background-color: var(--backgroundBoxColor);
}
.containerEditable.disappear {
	bottom: -50px;
	animation-duration: .5s;
	animation-name: disappearError;
}
.containerEditable > .switchEditable > label {
	height: 36px;
	margin: 0;
}
.containerEditable > .switchEditable > label > span {
	display: none;
}
.containerEditable > .switchEditable > label > .react-switch {
	float: right;
	margin: 15px 15px 0 0;
	padding: 0;
}
.containerEditable > p {
	margin: 0;
	padding: 0;
	font-size: 10pt;
	line-height: 50px;
	color: var(--white);
}

@keyframes appearError {
	0% {
		bottom: -50px;
	}
	50%{
		bottom: 40px;
	}
	100%{
		bottom: 15px;
	}
}
@keyframes disappearError {
	0% {
		bottom: 15px;
	}
	50%{
		bottom: 40px;
	}
	100%{
		bottom: -50px;
	}
}